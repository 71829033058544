import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import SummaryCard from "../components/SummaryCard"

const IndexPage = ({ data }) => {
  const latestArticles = data.latestArticles
  const latestLore = data.latestLore
  const latestNews = data.latestNews

  const subsections = {
    "Latest articles": latestArticles,
    "Latest lore": latestLore,
    News: latestNews,
  }

  return (
    <>
      <Seo />
      <div className="home">
        <div className="home-section">
          <h1>Welcome</h1>
          <hr />
          <p>
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut
            erat vitae quam auctor convallis ut et enim. Proin commodo lacus
            ultricies, rhoncus ante et, tincidunt ipsum. Praesent semper luctus
            sollicitudin. Vivamus vitae orci vestibulum, sagittis quam vel,
            molestie orci. Donec porttitor enim in malesuada varius. Ut
            vulputate, lacus non gravida molestie, est mauris rutrum eros, eu
            mattis augue est ut turpis. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. Orci varius
            natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Quisque ac mi massa. Maecenas eu bibendum velit, at
            tempor elit. Nam mattis vestibulum mattis. Pellentesque sollicitudin
            rhoncus pulvinar. Etiam tristique condimentum neque, pretium
            faucibus enim semper nec.{" "}
          </p>
        </div>
        {Object.keys(subsections).map(key => (
          <div key={key} className="home-section cell">
            <h1>{key}</h1>
            <hr />
            {subsections[key].edges.map(post => (
              <SummaryCard
                key={post.node.id}
                title={post.node.frontmatter.title}
                date={post.node.frontmatter.date}
                path={post.node.frontmatter.path}
                excerpt={post.node.excerpt}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export const query = graphql`
  query homePage {
    latestArticles: allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(articles)/" } }
    ) {
      edges {
        node {
          frontmatter {
            path
            date
            title
          }
          excerpt
          id
        }
      }
    }
    latestLore: allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(lore)/" } }
    ) {
      edges {
        node {
          frontmatter {
            path
            date
            title
          }
          excerpt
          id
        }
      }
    }
    latestNews: allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(news)/" } }
    ) {
      edges {
        node {
          frontmatter {
            path
            date
            title
          }
          excerpt
          id
        }
      }
    }
  }
`

export default IndexPage
